import { useState, useEffect } from "react";
import { getAuth, applyActionCode } from "firebase/auth";
import { Card, CardContent, Typography } from "@mui/material";
import { parentsFirebase } from "app/firebaseConfig";
import { Spin } from "common/element";

export const VerifyEmail = ({
  actionCode,
  continueUrl,
  lang,
}: {
  actionCode: string;
  continueUrl: string;
  lang: string;
}) => {
  const auth = getAuth(parentsFirebase);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    applyActionCode(auth, actionCode)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.error(error);
        // auth/invalid-action-code
        setLoading(false);
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-col-center w-full h-full">
      {loading && <Spin />}
      {!loading && success && (
        <Card sx={{ maxWidth: 360, minHeight: 200 }}>
          <CardContent>
            <Typography variant="h6" component="div" mt={3} mx={3}>
              メールアドレスは確認済みです
            </Typography>
            <Typography variant="body1" my={3} mx={3}>
              新しいアカウントでログインできるようになりました
            </Typography>
          </CardContent>
        </Card>
      )}
      {!loading && error && (
        <Card sx={{ maxWidth: 350 }}>
          <CardContent>
            <Typography variant="h6" component="div" mt={3} mx={3}>
              メールアドレスを再度確認してください
            </Typography>
            <Typography variant="body1" my={3} mx={3}>
              メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
