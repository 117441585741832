import { useState, useEffect, FormEvent } from "react";
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  Box,
  Card,
  Stack,
  Button,
  TextField,
  Typography,
  IconButton,
  CardContent,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { parentsFirebase } from "app/firebaseConfig";
import { SpinModal } from "common/element";

export const ResetPassword = ({
  actionCode,
  continueUrl,
  lang,
}: {
  actionCode: string;
  continueUrl: string;
  lang: string;
}) => {
  const auth = getAuth(parentsFirebase);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setChange(true);
        setEmail(email);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password").toString();
    if (password.length < 6) {
      setPasswordError(true);
    } else {
      setLoading(true);
      confirmPasswordReset(auth, actionCode, password)
        .then((resp) => {
          setChange(false);
          setSuccess(true);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="flex-col-center w-full h-full">
      {loading && <SpinModal />}
      {change && (
        <Stack
          mt={8}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            mt={1}
            width="100%"
            component="form"
            onSubmit={handleResetPassword}
          >
            <Typography variant="h6" sx={{ mt: 2 }} align="center">
              パスワードの再設定
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }} align="center">
              メールアドレス: {email}
            </Typography>
            <TextField
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              error={passwordError}
              margin="normal"
              variant="standard"
              required
              fullWidth
              label={"新しいパスワード"}
              onChange={() => setPasswordError(false)}
              helperText={
                passwordError && "6文字以上のパスワードを入力してください"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              保存
            </Button>
          </Box>
        </Stack>
      )}
      {success && (
        <Card sx={{ maxWidth: 360, minHeight: 200 }}>
          <CardContent>
            <Typography variant="h6" component="div" mt={3} mx={3}>
              パスワードを変更しました
            </Typography>
            <Typography variant="body1" my={3} mx={3}>
              新しいパスワードでログインできようになりました
            </Typography>
          </CardContent>
        </Card>
      )}
      {error && (
        <Card sx={{ maxWidth: 350 }}>
          <CardContent>
            <Typography variant="h6" component="div" mt={3} mx={3}>
              パスワードの再設定をもう一度お試しください
            </Typography>
            <Typography variant="body1" my={3} mx={3}>
              パスワードの再設定のリクエストの期限が切れたか、リンクがすでに使用されています
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
