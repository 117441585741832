import { initializeApp } from "firebase/app";
import Constants from "common/constant";

const firebaseConfig = {
  apiKey: Constants.apiKey,
  authDomain: Constants.authDomain,
  projectId: Constants.projectId,
  appId: Constants.appId,
};

const firebase = initializeApp(firebaseConfig);

export default firebase;

const parentsFirebaseConfig = {
  apiKey: Constants.parentsApiKey,
  authDomain: Constants.parentsAuthDomain,
  projectId: Constants.parentsProjectId,
  appId: Constants.parentsAppId,
};

export const parentsFirebase = initializeApp(parentsFirebaseConfig, "parents");
