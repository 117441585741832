const Constants = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  parentsApiKey: process.env.REACT_APP_PARENTS_FIREBASE_API_KEY,
  parentsAuthDomain: process.env.REACT_APP_PARENTS_FIREBASE_AUTH_DOMAIN,
  parentsProjectId: process.env.REACT_APP_PARENTS_FIREBASE_PROJECT_ID,
  parentsAppId: process.env.REACT_APP_PARENTS_FIREBASE_APP_ID,
  kidsHost: process.env.REACT_APP_KIDS_HOST,
};

export default Constants;
