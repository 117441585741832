import { useSearchParams } from "react-router-dom";
import { VerifyEmail } from "./VerifyEmail";
import { ResetPassword } from "./ResetPassword";

export const MailActionHandler = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "en";

  switch (mode) {
    case "resetPassword":
      return (
        <ResetPassword
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
    case "verifyEmail":
      return (
        <VerifyEmail
          actionCode={actionCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      );
    default:
      return <div></div>;
  }
};
