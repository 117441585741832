import { useEffect } from "react";
import { Stack, Button } from "@mui/material";
import * as utils from "common/utils";
import Constants from "common/constant";

export const Home = () => {
  useEffect(() => {
    if (utils.isIos()) {
      const ifr = document.createElement("iframe");
      ifr.src = "com.inexus.app.kids://";
      ifr.style.display = "none";
      document.body.appendChild(ifr);
      window.setTimeout(function () {
        document.body.removeChild(ifr);
        window.location.href = "https://apps.apple.com/jp/app/id1590656071";
      }, 1000);
    } else if (utils.isAndroid()) {
      const ifr = document.createElement("iframe");
      ifr.src = "com.inexus.app.kids://";
      ifr.style.display = "none";
      document.body.appendChild(ifr);
      // window.setTimeout(function () {
      //   document.body.removeChild(ifr);
      //   if (utils.isFire()) {
      //     window.location.href = "amzn://apps/android?p=com.inexus.app.kids";
      //   } else {
      //     window.location.href =
      //       "https://play.google.com/store/apps/details?id=com.inexus.app.kids";
      //   }
      // }, 1000);
    } else {
      window.location.replace(`${Constants.kidsHost}`);
    }
  }, []);

  const handleOpenIos = () => {
    const ifr = document.createElement("iframe");
    ifr.src = "com.inexus.app.kids://";
    ifr.style.display = "none";
    document.body.appendChild(ifr);
    window.setTimeout(function () {
      document.body.removeChild(ifr);
      window.location.href = "https://apps.apple.com/jp/app/id1590656071";
    }, 1000);
  };

  const handleOpenAndroid = () => {
    window.open("com.inexus.app.kids://?test=link");

    // const ifr = document.createElement("iframe");
    // ifr.src = "com.inexus.app.kids://";
    // ifr.style.display = "none";
    // document.body.appendChild(ifr);
    // window.setTimeout(function () {
    //   document.body.removeChild(ifr);
    //   if (utils.isFire()) {
    //     window.location.href = "amzn://apps/android?p=com.inexus.app.kids";
    //   } else {
    //     window.location.href =
    //       "https://play.google.com/store/apps/details?id=com.inexus.app.kids";
    //   }
    // }, 1000);
  };

  return (
    <Stack
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Button
        variant="contained"
        onClick={utils.isIos() ? handleOpenIos : handleOpenAndroid}
      >
        ダウンロード
      </Button>
    </Stack>
  );
};
